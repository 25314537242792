.focused {
  width: 60px;
  height: 60px;
  display: block;
  cursor: pointer;
  z-index: 3;
}

.focused__wrap {

  width: 100%;
  height: 100%;
}

.focused__wrapper {
  width: 60px;
  height: 60px;
  display: inline;
  text-align: center;
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, .2);
  background: rgba(white, .4);
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.focused__icon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-right: none;
  border-bottom: none;
  pointer-events: none;
}

@media (max-width: 414px) {
  .focused__wrapper {
    width: 42px;
    height: 42px;
  }
}