.home {
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home__container {
}
.pc{
  display: block;
}
.mob{
  display: none;
}
@media (max-width:700px){
  .pc{
    display: none;
  }
  .mob{
    display: block;
  }
}
/* .home__content {
  position: relative;
  z-index: 3;
} */

.home__subtitle {
  max-width: 390px;
  line-height: 1.25;
  color: #fff !important;
  font-size: 18px;
  font-weight: 300;
  margin: 24px 0;
  position: relative;
  z-index: 3;
}

.home__title {
  color: #fff !important;
  font-size: 100px;
  font-weight: 900;
  white-space: nowrap;
  text-transform: uppercase;
  overflow: hidden;
  line-height: 1;
}

.home__title_up {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 100px;

}

.home__title_up p {
  position: relative;
  font-size: 40px;
  white-space: normal;
  z-index: 3;
}

.home__letter {
  transform: translateX(100%);
}

.home__letter-overflow {
  display: inline-block;
  white-space: pre;
  overflow: hidden;
  position: relative;
  z-index: 3;
}

.home__letter_animation {
  animation-name: Home__letter_animation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--app-transition);
}

.home__letter-overflow:nth-child(1)  .home__letter_animation { animation-delay: .1s;}
.home__letter-overflow:nth-child(2)  .home__letter_animation { animation-delay: .15s;}
.home__letter-overflow:nth-child(3)  .home__letter_animation { animation-delay: .2s;}
.home__letter-overflow:nth-child(4)  .home__letter_animation { animation-delay: .25s;}
.home__letter-overflow:nth-child(5)  .home__letter_animation { animation-delay: .3s;}
.home__letter-overflow:nth-child(6)  .home__letter_animation { animation-delay: .35s;}
.home__letter-overflow:nth-child(7)  .home__letter_animation { animation-delay: .4s;}
.home__letter-overflow:nth-child(8)  .home__letter_animation { animation-delay: .45s;}
.home__letter-overflow:nth-child(9)  .home__letter_animation { animation-delay: .5s;}
.home__letter-overflow:nth-child(10) .home__letter_animation { animation-delay: .55s;}
.home__letter-overflow:nth-child(11) .home__letter_animation { animation-delay: .6s;}
.home__letter-overflow:nth-child(12) .home__letter_animation { animation-delay: .65s;}
.home__letter-overflow:nth-child(13) .home__letter_animation { animation-delay: .7s;}
.home__letter-overflow:nth-child(14) .home__letter_animation { animation-delay: .75s;}
.home__letter-overflow:nth-child(15) .home__letter_animation { animation-delay: .8s;}
.home__letter-overflow:nth-child(16) .home__letter_animation { animation-delay: .85s;}

.home__text {
  color: #fff !important;
  font-size: 18px;
  font-weight: 700;
  margin: 24px 0;
  position: relative;
  z-index: 3;
}

.home__video-src {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.home__video {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.home__video::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  background: black;
  transition: opacity 1s var(--app-transition);
  transition-delay: .5s;
}

.home_active .home__video::before {
  opacity: .7;
}

@keyframes Home__letter_animation {
  0% { transform: translateX(100%) } 
  20% { transform: translateX(0) }
  40% { transform: translateX(0) }
  60% { transform: translateX(0) }
  80% { transform: translateX(0) }
  100% { transform: translateX(-100%) }
}
@media (max-width: 1000px){
  .home__title {
    font-size: 70px;

  }
}
@media (max-width: 740px) {
  .home__title_up {
    gap: 64px;
  }
  .home__title_up p{
    white-space: normal;
    font-size: 25px;
  }
  .home__title_up{
white-space: normal }
  }

@media (max-width: 640px) {
  .home__video-src {
    padding: 0;
  }

  .home__title {
    font-size: 40px;
  }
}

@media (max-width: 414px) {
  .home__title {
    font-size: 25px;
  }

  .home__title_up {
    gap: 34px;
  }
  .home__title_up p{
    white-space: normal;
    font-size: 20px;
  }
}