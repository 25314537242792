.about {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}

.about__container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.about__title {
  font-size: 38px;
  font-weight: 900;
  margin: 0 0 18px 0;
  opacity: 0;
  filter: blur(6px);
  transform: translate(0, 10px);
  transition: opacity 1s var(--app-transition), transform 1s var(--app-transition), filter 1s var(--app-transition);
  will-change: filter, transform;
}

.about_active .about__title {
  opacity: 1;
  filter: blur(0);
  transform: translate(0, 0);
}

.about_active .about__row:nth-child(1) .about__title {
  transition-delay: .2s;
}

.about_active .about__row:nth-child(2) .about__title {
  transition-delay: .3s;
}

.about__text {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  opacity: 0;
  filter: blur(6px);
  transform: translate(0, 10px);
  transition: opacity 1s var(--app-transition), transform 1s var(--app-transition), filter 1s var(--app-transition);
  will-change: filter, transform;
}

.about_active .about__text {
  opacity: 1;
  filter: blur(0);
  transform: translate(0, 0);
}

.about_active .about__row:nth-child(1) .about__text {
  transition-delay: .3s;
}

.about_active .about__row:nth-child(2) .about__text {
  transition-delay: .4s;
}

.about__content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 64px;
}

.about__left {}

.about__right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
}

.about__counter {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 18px;
  filter: blur(6px);
  opacity: 0;
  transition: opacity 1s var(--app-transition), filter 1s var(--app-transition);
  will-change: filter, transform, opacity;
}

.about_active .about__counter {
  filter: blur(0);
  opacity: 1;
}

.about_active .about__counter:nth-child(1) {
  transition-delay: .5s;
}

.about_active .about__counter:nth-child(2) {
  transition-delay: .6s;
}

.about_active .about__counter:nth-child(3) {
  transition-delay: .7s;
}

.about__count {
  font-size: 48px;
  font-weight: 900;
  position: relative;
  padding: 0 0 8px 0;
}

.about__count::before {
  content: '';
  width: 100%;
  height: 4px;
  max-width: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--font-color);
}

.about_active .about__count::before {
  max-width: 24px;
  opacity: 0;
  transition: opacity 1s var(--app-transition), max-width 1s var(--app-transition);
}

.about_active .about__counter:nth-child(1) .about__count::before {
  opacity: 1;
  transition-delay: .6s;
}

.about_active .about__counter:nth-child(2) .about__count::before {
  opacity: 1;
  transition-delay: .7s;
}

.about_active .about__counter:nth-child(3) .about__count::before {
  opacity: 1;
  transition-delay: .8s;
}

.about__row {
  margin: 0 0 48px 0;
}

.about__row:last-child {
  margin: 0 0 0 0;
}

.about__marquee {
  min-height: 30px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  opacity: 0;
  transition: opacity 1s var(--app-transition);
}

.about_active .about__marquee {
  opacity: 1;
  transition-delay: 1s;
}

.about__marquee-word {
  font-weight: 200;
  font-size: 24px;
  padding: 0 12px;
}

.about__marquee-link {
  text-decoration: none;
  opacity: .2;
  transition: opacity .4s var(--app-transition);
}

.about__marquee-link:hover {
  opacity: 1;
}

@media (max-width: 640px) {
  .about__content {
    flex-direction: column;
    gap: 24px;
  }

  .about__right {
    flex-direction: row;
    text-align: center;
  }

  .marquee:first-child {
    display: none;
  }

  .about__marquee-link {
    opacity: .5
  }

  .about__count::before {
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .about__title {
    font-size: 24px;
  }

  .about__marquee.marquee-container {
    display: none !important;
  }

  .app__focused-window {
    padding: 24px !important;
  }

  .app__focused-title {
    font-size: 24px !important;
  }

}