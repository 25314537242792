.menu {
  width: 380px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  background: var(--app-background);
  z-index: 2000;
  padding: 18px 24px;
  border-left: 1px solid var(--border-color);
  transition: transform .5s var(--app-transition);
  transition-delay: 0s;
  overflow-y: auto;
  overflow-x: hidden;
}

.menu_active {
  transform: translate(0);
  transition-delay: 0s;
}

.menu__nav {
  display: flex;
  flex-direction: column;
  margin: 18px 0;
  opacity: 0;
  transform: translate(20px, 0);
  transition: opacity .5s var(--app-transition), transform .5s var(--app-transition);
  transition-delay: 0s;
}

.menu_active .menu__nav {
  opacity: 1;
  transform: translate(0, 0);
  transition-delay: .3s;
}

.menu__title {
  font-size: 12px;
  font-weight: 900;
}

.menu__download {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 0 24px 0;
  text-decoration: none;
  line-height: 1.5;
  transition: .4s var(--app-transition);
}

.menu__download-icon {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--border-color);
  border-radius: 38px;
}

.menu__download-icon::before {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 2px solid var(--font-color);
  border-top: none;
  border-left: none;
  z-index: 3;
}

.menu__download-icon::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  background: red;
  border-radius: 50%;

  background-image: linear-gradient(90deg, #ee7752, #e73c7e, #ee7752, #e73c7e, #23a6d5, #ee7752);
  -webkit-text-fill-color: transparent;
  animation: gradient 20s linear infinite;
  background-size: 400%;
  transition: transform .4s var(--app-transition);
}

.menu__download-text:last-child {
  opacity: .5;
}

.menu__download:hover .menu__download-icon::after {
  transform: translate(-50%, -50%) scale(1);
}

.menu__link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 12px 0;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.menu__link-text {
  position: relative;
  display: inline;
  background: linear-gradient(90deg, #ee7752, #e73c7e, #ee7752, #e73c7e, #23a6d5, #ee7752);
  background-size: 200% 200%;
  background-clip: text;

  animation-name: menu__link_animation_out;
  animation-fill-mode: backwards;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
  animation-duration: .4s;
}

.menu__link:hover .menu__link-text {
  animation-name: menu__link_animation_in;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-duration: .4s;
}

.menu__link.active .menu__link-text {
  opacity: 1;
  animation: footerLinkGradient 5s ease-in-out infinite;
}

/* .menu__link:nth-child(1) .menu__link-text::after {
  content: 'HOME';
} */

/* .menu__link:nth-child(2) .menu__link-text::after {
  content: 'ABOUT';
} */

/* .menu__link:nth-child(3) .menu__link-text::after {
  content: 'SHOWREELS';
} */

/* .menu__link:nth-child(4) .menu__link-text::after {
  content: 'CONTACTS';
} */

.menu__link-text::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -250%);
}

.menu__link.active .menu__link-text {
  color: rgba(0, 0, 0, 0)
}

.menu__link-text {
  color: var(--font-color);
  opacity: .7;
  transition: opacity .2s ease;
}

.menu__link:hover .menu__link-text {
  /* color: rgba(0, 0, 0, 0) */
  opacity: 1;
}

/* .menu__link:hover .header__link-text {
  animation-name: menu__link_animation_in;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-duration: .1s;
} */

/* @keyframes menu__link_animation_in {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 200%);
  }
}

@keyframes menu__link_animation_out {
  from {
    transform: translate(0, 200%);
  }

  to {
    transform: translate(0, 0%);
  }
} */

.menu__toggle-src {
  display: none;
}

.menu .form {
  opacity: 1;
}

.menu__toggle {
  width: 32px;
  height: 16px;
  border-radius: 64px;
  position: relative;
  background: var(--font-color);
  border: 2px solid var(--font-color);
}

.menu__toggle::before {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(100%, -50%);
  border-radius: 14px;
  background: var(--app-background);
}

.menu__toggle-src:checked~.menu__toggle::before {
  transform: translate(0, -50%);
}

.menu__contacts {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 24px 0;
}

.menu__text:last-child {
  font-size: 14px;
  font-weight: 400;
}

.menu__mail {
  font-size: 16px;
  font-weight: 900;
  text-decoration: none;
}

.menu__tel {
  font-size: 22px;
  font-weight: 900;
  text-decoration: none;
}

.menu__close {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  margin-left: auto;
}

.menu__close::after {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  background: var(--font-color);
  transition: width .4s var(--app-transition);
}

.menu__close::before {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: var(--font-color);
  transition: width .4s var(--app-transition);
}

.menu_active .menu__close::before {
  width: 24px;
  transition-delay: .4s;
}

.menu_active .menu__close::after {
  width: 24px;
  transition-delay: .5s;
}

@media (max-width: 640px) {
  .menu__link {
    font-size: 18px;
  }
}