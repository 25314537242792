.c-cursor {
  z-index: 99999;
}

@media (max-width: 640px) {
  .c-cursor {
    display: none;
  }
}

.app {
  height: var(--app-height);
  display: grid;
  grid-template-areas:
    'sidebar-left header sidebar-right'
    'sidebar-left content sidebar-right'
    'sidebar-left footer sidebar-right';
  grid-template-columns: var(--app-sidebar-width) var(--app-content-max-height) var(--app-sidebar-width);
  grid-template-rows: var(--app-header-height) var(--app-content-max-height) var(--app-footer-height);
  line-height: 1.15;

  color: var(--font-color);
  background-color: var(--app-background);
  position: relative;
  overflow: hidden;
}

.app__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, .2);
  backdrop-filter: blur(6px);
  z-index: 1500;
  transition: opacity .2s var(--app-transition);
  will-change: opacity;
}

.app__overlay_active {
  opacity: 1;
  pointer-events: auto;
}

.app_home .app__header,
.app_home .app__sidebar_left,
.app_home .app__sidebar_right,
.app_home .footer__nav,
.app_home .footer__nav .footer__link,
.app_home .app__footer {
  border-color: #303030;
}

.app__home .header__burger::before,
.app__home .header__burger::after {
  background: white !important;
}

.app_home .footer .footer__link {
  color: white;
}

.app_home .header__pdf-icon::before {
  border-color: white;
}

.app_home .header__pdf {
  color: white;
}

.app_home .header__pdf-icon {
  border-color: white;
}

.app_home .date {
  color: white;
}

.app_home .header__burger-icon {
  background: #fff;
}

.app_home .header__logo path {
  fill: white;
}

.app_home .footer__link-arrow {
  background: white;
}

.app_home .footer__link-arrow::before {
  border-color: white;
}

.app_home .footer .footer__nav .footer__link {
  color: rgba(255, 255, 255, 1);
}

.app_home .footer .footer__nav .footer__link.active {
  color: rgba(0, 0, 0, 0);
}

.app_home .footer__nav .footer__link::before {
  background: #fff;
}

.app_home .footer__nav .footer__link:hover {
  color: rgba(0, 0, 0, 0);
}

.app__overflow {
  height: var(--app-main-height);
  overflow-y: auto;
  overflow-x: hidden;
}

.app__header {
  border-bottom: 1px solid var(--border-color);
  grid-area: header;
  z-index: 1000;
}

.app__content {
  grid-area: content;
  overflow-y: auto;
  overflow-x: hidden;
}

.app__sidebar {
  z-index: 1000;
}

.app__sidebar_left {
  border-right: 1px solid var(--border-color);
  grid-area: sidebar-left;
}

.app__sidebar_right {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--border-color);
  grid-area: sidebar-right;
}

.app__main {
  height: var(--app-main-height);
}

.app__footer {
  border-top: 1px solid var(--border-color);
  grid-area: footer;
  z-index: 1000;
}

.app__focused {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  padding: 24px;
  transform: translate(-50%, -50%);
  transition: opacity .2s var(--app-transition);
  z-index: 9999;
  pointer-events: none;
  transition-delay: 0s;
}

.app__focused-window {
  width: 100%;
  max-width: 640px;
  max-height: 100%;
  overflow-y: auto;
  padding: 64px;
  background: var(--app-background);
  opacity: 0;
  transform: translate(0, 20px);
  transition: transform .4s var(--app-transition), opacity .4s var(--app-transition);
  transition-delay: .4s;


  /* max-height: 100%;
  padding: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  overflow-y: auto;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s var(--app-transition);
  background: var(--app-background); */
}

.app__focused_active {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%);
  transition-delay: 0s;
}

.app__focused-close {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}

.app__focused-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 24px 0;
}

.app__focused-close::after {
  content: '';
  width: 24px;
  height: 2px;
  background: var(--font-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.app__focused-close::before {
  content: '';
  width: 24px;
  height: 2px;
  background: var(--font-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.app__focused-title {
  font-size: 30px;
  font-weight: 900;
  margin: 0 0 12px 0;
  filter: blur(6px);
  opacity: 0;
  transition: filter 1s var(--app-transition), opacity 1s var(--app-transition), transform 1s var(--app-transition);
}

.app__focused-text {
  font-weight: 300;
  margin: 0 0 30px 0;
  line-height: 1.5;
  filter: blur(6px);
  opacity: 0;
  transition: filter 1s var(--app-transition), opacity 1s var(--app-transition), transform 1s var(--app-transition);
}

.app__focused_active .app__focused-window {
  opacity: 1;
  transform: translate(0, 0);
  transition-delay: .4s;
}

.app__focused_active .app__focused-title {
  opacity: 1;
  filter: blur(0);
  will-change: opacity, filter;
}

.app__focused_active .app__focused-title_1 {
  transition-delay: .2s;
}

.app__focused_active .app__focused-title_2 {
  transition-delay: .3s;
}

.app__focused_active .app__focused-title_3 {
  transition-delay: .4s;
}

.app__focused_active .app__focused-text {
  opacity: 1;
  filter: blur(0);
  will-change: opacity, filter;
}

.app__focused_active .app__focused-text_1 {
  transition-delay: .25s;
}

.app__focused_active .app__focused-text_2 {
  transition-delay: .35s;
}

.app__focused_active .app__focused-text_3 {
  transition-delay: .45s;
}

.app__loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background: var(--app-background);
  animation-name: app__loader_animation;
  animation-duration: 2s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--app-transition);
  transform: translate(0, 0);
}

.app__loader svg {
  width: 100%;
  max-width: 300px;
}

.app__loader svg {
  opacity: 1;
  filter: blur(0);
  animation-name: app__logo_animation_out;
  animation-duration: 1s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--app-transition);
  transform: translate(0, 0);
}

.app__loader svg path {
  opacity: 0;
  filter: blur(10px);
  animation-name: app__logo_animation_in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--app-transition);
  transform: translate(0, 100%);
}

.app__loader svg path:nth-child(1) {
  animation-delay: .1s;
}

.app__loader svg path:nth-child(2) {
  animation-delay: .15s;
}

.app__loader svg path:nth-child(3) {
  animation-delay: .2s;
}

.app__loader svg path:nth-child(4) {
  animation-delay: .25s;
}

.app__loader svg path:nth-child(5) {
  animation-delay: .3s;
}

.app__loader svg path:nth-child(6) {
  animation-delay: .35s;
}

.app__loader svg path:nth-child(7) {
  animation-delay: .4s;
}

.app__loader svg path:nth-child(8) {
  animation-delay: .45s;
}

.app__loader-text {
  opacity: 0;
  filter: blur(10px);
  animation-name: app__logo_animation_text;
  animation-duration: 4s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--app-transition);
  transform: translate(0, 100%);
}

@keyframes app__logo_animation_in {
  from {
    opacity: 0;
    filter: blur(10px);
    transform: translate(0, -10px)
  }

  to {
    opacity: 1;
    filter: blur(0);
    transform: translate(0, 0)
  }
}


@keyframes app__logo_animation_out {
  from {
    opacity: 1;
    filter: blur(0);
    transform: translate(0, 0)
  }

  to {
    opacity: 0;
    filter: blur(10px);
    transform: translate(0, 10px)
  }
}

@keyframes app__loader_animation {
  from {
    opacity: 1
  }

  to {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes app__logo_animation_text {
  0% {
    opacity: 0;
    filter: blur(10px);
    transform: translate(0, -10px)
  }

  20% {
    opacity: 0;
    filter: blur(10px);
    transform: translate(0, 10px)
  }

  40% {
    opacity: 1;
    filter: blur(0);
    transform: translate(0, 0)
  }

  60% {
    opacity: 1;
    filter: blur(0);
    transform: translate(0, 0)
  }

  80% {
    opacity: 0;
    filter: blur(10px);
    transform: translate(0, 10px)
  }

  100% {
    opacity: 0;
    filter: blur(10px);
    transform: translate(0, 10px)
  }
}

@media (max-width: 640px) {
  .app {
    grid-template-areas:
      'header header header'
      'content content content'
      'footer footer footer';
  }

  .app__sidebar {
    display: none;
  }

  .app__menu {
    width: 100%;
    border: none;
  }

  .app__focused {
    width: 100%;
    max-width: 100%;
  }
}