.video {
  height: 100%;
  min-height: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}

.video__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}

.video__title {
  font-size: 16px;
  font-weight: 700;
}

.video__src {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video__link {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}

.video__link-icon {
  width: 24px;
  height: 2px;
  position: relative;
  background: var(--font-color);
}

.video__link-icon::before {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%) rotate(135deg);
  border: 2px solid var(--font-color);
  border-top: none;
  border-left: none;
}